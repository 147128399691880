import { Icon } from "@components/ui/Icon"
import { Toast, toaster } from "@kobalte/core/toast"
import clsx from "clsx"
import { Ban, Check, X } from "lucide-static"
import type { ParentComponent } from "solid-js"
import { Portal } from "solid-js/web"

export type ToastMessage = {
  title: string
  description?: string
  type?: "primary" | "success" | "error"
}

export const Toaster: ParentComponent<{ toastId: number; message: ToastMessage }> = (props) => {
  return (
    <Toast
      toastId={props.toastId}
      class={clsx(
        "pointer-events-auto relative rounded-xl border border-[#323E3E] bg-[#111715] text-bright shadow-xl",
        "animate-in fade-in-0 slide-in-from-right-14",
        props.message.type === "success" && "border-lime-800",
        props.message.type === "error" && "border-red-800",
      )}
    >
      <div class="flex items-center justify-between gap-4 p-4">
        {props.message.type === "success" && <Icon svg={Check} class="-mr-2 size-4 flex-none text-lime-500" />}
        {props.message.type === "error" && <Icon svg={Ban} class="-mr-2 size-4 flex-none text-red-500" />}
        <div class="flex flex-col gap-1">
          <Toast.Title class="text-base font-medium text-bright">{props.message.title}</Toast.Title>
          {props.message.description && (
            <Toast.Description class="text-sm text-fog">{props.message.description}</Toast.Description>
          )}
        </div>
        <Toast.CloseButton class="flex size-4 flex-none items-center justify-center text-fog hover:text-vapor">
          <Icon svg={X} class="size-5 flex-none *:stroke-[4px]" />
        </Toast.CloseButton>
      </div>
      <Toast.ProgressTrack class="absolute inset-x-4 -bottom-px h-px mix-blend-darken">
        <Toast.ProgressFill class="to-black-0 absolute right-0 h-full w-[--kb-toast-progress-fill-width] bg-gradient-to-r from-black/50">
          <div class="to-black-0 absolute right-0 h-full w-full bg-gradient-to-r from-black/50" />
        </Toast.ProgressFill>
      </Toast.ProgressTrack>
    </Toast>
  )
}

export const toasterCtx = {
  show: (message: ToastMessage) => {
    return toaster.show((props) => <Toaster toastId={props.toastId} message={message} />)
  },
  hide: (toastId: number) => {
    return toaster.dismiss(toastId)
  },
  clear: () => toaster.clear(),
  success: (title: string, description?: string) => {
    toasterCtx.show({ title, type: "success", description })
  },
  error: (title: string | Error, description?: string) => {
    if (title instanceof Error) {
      title = title.message
    }
    toasterCtx.show({ title, type: "error", description })
  },
}

export const ToasterArea = (props: { messages?: ToastMessage[] }) => {
  if (props.messages) {
    props.messages.forEach((message) => {
      toasterCtx.show(message)
    })
  }
  return (
    <Portal>
      <Toast.Region class="!pointer-events-none fixed bottom-0 right-0" limit={5} pauseOnInteraction pauseOnPageIdle>
        <Toast.List class="pointer-events-none flex flex-col-reverse items-end justify-end gap-4 overflow-y-auto p-8" />
      </Toast.Region>
    </Portal>
  )
}
